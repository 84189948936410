.page {
    height: 100vh;
    background-color: white
}

.search {
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.searchInput {
    width: 90%;
    height: 35px;
    border-radius: 50px;
    margin-left: 15px;
    margin-left: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 10px;
    outline: none !important;
    border: 1px #808080 solid;
    background-color: #F5F5F5;
}

.searchButton {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #700f9d;
    border-radius: 50px;
    padding: 8px;
    margin-right: 15px;
}

.searchMenu {
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.tab {
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.tabLabel {
    width: 25%;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    color: #808080;
    padding-bottom: 3px;
    border-bottom: 1px solid #F1F1F1;
}

.tabLabelActive {
    width: 25%;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    color: #700f9d;
    padding-bottom: 3px;
    border-bottom: 2px solid #700f9d;
}

.tabBadge {
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #808080;
}

.tabBadgeActive {
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #700f9d;
}

.tabView {
    height: 66vh;
    display: none;
}

.tabViewActive {
    height: 66vh;
    display: block;
}